import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";

import Header from "./header";
import Footer from "./footer";

import { useCartCount } from 'gatsby-theme-shopify-manager';

import '../assets/menu-default.css';
import '../assets/main.css';
import './EveryLayout/everylayout.css';

const Layout = ({ children, location }) => {
  // Sum the quantity of each variant in the cart, don't just return the
  // quantity of items. Always return a number; return 0 if cart is null.
  // https://github.com/thetrevorharmon/gatsby-theme-shopify-manager/blob/9032a07448d5d811f49dadb4e368026a679b8b1f/docs/src/content/hooks/useCartCount.mdx
  const cartCount = useCartCount();

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="flex flex-col h-screen justify-between">
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
      >
        <title>{data.site.siteMetadata?.title || 'My Site'}</title>
      </Helmet>
      <Header
        location={location}
        cartCount={cartCount}
      />
      <main className="grow">
        {children}
        {/* Used to notify screen readers of content changes. */}
        <div id="page-status" className="sr-only" role="status" aria-live="polite" />
      </main>
      <Footer />
    </div>
  )
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default Layout;
