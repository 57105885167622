import React from 'react';
import PropTypes from 'prop-types';

const Banner = ({ message }) => {
  return (
    <div
      className="relative bg-edgewater-300 dark:bg-black"
      role="alert"
    >
      <div className="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8">
        <div className="sm:px-16 sm:text-center dark:text-white">
          <span className="banner text-center" dangerouslySetInnerHTML={{__html: message }} />
        </div>
      </div>
    </div>
  )
};

Banner.propTypes = {
  message: PropTypes.string.isRequired,
};

export default Banner;
