import React from 'react';
import { navigate } from 'gatsby-link';

/**
 * Netlify + Gatsby form example:
 * https://github.com/sw-yx/gatsby-netlify-form-example-v2/blob/master/src/pages/contact.js
 */

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

/* eslint-disable-next-line */
export default () => {
  const [state, setState] = React.useState({});

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error));
  };

  return (
    <form
      name="notify"
      method="post"
      action="/thank-you/"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
      className=""
    >
      <input type="hidden" name="form-name" value="notify" />
      <p hidden>
        <label aria-hidden="true">
          Don’t fill this out: <input name="bot-field" onChange={handleChange} />
        </label>
      </p>
      {/* eslint-disable-next-line */}
      <label className="block text-center sm:text-left text-lg md:text-2xl dark:text-white" htmlFor="emailAddress">Join my mailing list!</label>
      <div className="md:max-w-lg flex flex-wrap items-center justify-center">
        <div className="w-full sm:w-2/3 md:w-3/4 p-2 sm:pl-0 sm:py-4">
          <input
            onChange={handleChange}
            placeholder="Enter your email"
            id="emailAddress"
            name="email"
            type="email"
            autoComplete="email"
            required
            className="w-full text-center sm:text-left placeholder-gray-500 bg-white border-0 py-3 px-4"
          />
        </div>
        <div className="w-full sm:w-1/3 md:w-1/4 p-2 sm:py-4">
          <button
            type="submit"
            aria-label="Submit"
            className="w-full font-display bg-white text-3xl hand-drawn border-2 border-edgewater-900 bg-edgewater-400 hover:bg-edgewater-300 py-2 px-4 dark:text-almond dark:bg-sandstone-700 dark:hover:bg-sandstone-800 dark:border-sandstone-900"
          >
            Join
          </button>
        </div>
      </div>
    </form>
  );
};
