import React from 'react';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';
import { Link, graphql, useStaticQuery } from 'gatsby';
import DropdownMenu from './DropdownMenu';
import { ShoppingCartIcon } from '@heroicons/react/24/outline';
import Banner from './Banner';

const Header = ({ location, cartCount }) => {
  const data = useStaticQuery(graphql`
    query {
      block_banner: prismicContentBlock(prismicId: {eq: "Y6sk6xAAAI1dId3j"}) {
        data {
          body {
            html
          }
        }
        id
        prismicId
      }
    }
  `)
  return (
    <header className="header bg-edgewater-500 dark:bg-edgewater-dark">
      {data.block_banner
        && (
          <Banner message={data.block_banner.data.body.html} />
        )
      }
      <div className="relative z-50 x-auto px-2 sm:px-4 md:px-6 py-4">
        <div className="container relative mx-auto">
          <div className="flex sm:flex-row gap-2 sm:gap-4 items-center">
            <div className="w-3/5 text-left">
              <Link to="/" aria-label="Nest Embirdery home" className="inline-block md:hidden dark:hidden hand-drawn no-trans px-2 py-2 sm:p-1 sm:px-4 bg-edgewater-300 border-b-0">
                <StaticImage
                  src="../images/nest-embirdery-alt-logo_mob.png"
                  width={140}
                  height={29}
                  placeholder="none"
                  layout="fixed"
                  role="presentation"
                  alt=""
                />
              </Link>
              <Link to="/" aria-label="Nest Embirdery home" className="hidden md:inline-block md:dark:hidden hand-drawn no-trans px-2 sm:p-1 sm:px-4 bg-edgewater-300 border-b-0">
                <StaticImage
                  src="../images/nest-embirdery-alt-logo.png"
                  width={228}
                  height={47}
                  placeholder="none"
                  layout="fixed"
                  role="presentation"
                  alt=""
                />
              </Link>
              {/* There is a CSS specificity issue in dark mode which requires us to create a 'xs' class for use here. */}
              <Link to="/" aria-label="Nest Embirdery home" className="hidden xs:dark:inline-block md:dark:hidden hand-drawn no-trans px-2 py-2 sm:p-1 sm:px-4 bg-edgewater-900 border-b-0">
                <StaticImage
                  src="../images/nest-embirdery-alt-logo_dark_mob.png"
                  width={140}
                  height={29}
                  placeholder="none"
                  layout="fixed"
                  role="presentation"
                  alt=""
                />
              </Link>
              <Link to="/" aria-label="Nest Embirdery home" className="hidden dark:hidden md:dark:inline-block hand-drawn no-trans px-2 sm:p-1 sm:px-4 bg-edgewater-900 border-b-0">
                <StaticImage
                  src="../images/nest-embirdery-alt-logo_dark.png"
                  width={228}
                  height={47}
                  placeholder="none"
                  layout="fixed"
                  role="presentation"
                  alt=""
                />
              </Link>
            </div>
            <div className="w-2/5 flex justify-end">
              <div className="relative ml-2 sm:ml-2 sm:mr-2 md:mr-4">
                <Link
                  aria-label={`Go to shopping cart (${cartCount} ${cartCount > 1 ? 'items' : 'item'})`}
                  className="border-b-0 p-2 inline-flex items-center justify-center"
                  to="/cart/"
                >
                  <ShoppingCartIcon className="h-6 w-6 sm:h-7 sm:w-7 stroke-1 text-edgewater-dark hover:text-gray-900 dark:text-edgewater-400 dark:hover:text-edgewater-700" aria-hidden="true" />
                  {cartCount > 0
                    && (
                    <span className="absolute -right-1 -top-1 rounded-full bg-dodger-blue-500 w-4 h-4 top right p-0 m-0 text-white text-xs leading-tight text-center">
                      {cartCount}
                    </span>
                  )}
                </Link>
              </div>
              <div className="">
                <DropdownMenu location={location} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  location: PropTypes.object,
};

export default Header;
