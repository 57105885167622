import React, { Fragment } from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import {
  Disclosure,
  Popover,
  Transition
} from '@headlessui/react';
import {
  PlusIcon,
  Bars4Icon
} from '@heroicons/react/24/outline';

const DropdownMenu = ({ location }) => {

  const data = useStaticQuery(graphql`
    query {
      collections: allShopifyCollection(
        sort: {
          title: ASC
        }
      ) {
        edges {
          node {
            title
            id
            collectionPath: gatsbyPath(filePath: "/shop/collection/{shopifyCollection.title}")
          }
        }
      }
      pages: allPrismicPage(
        sort: {
          order: ASC
        }
      ) {
        edges {
          node {
            data {
              title {
                text
              }
              weight
            }
            order
            uid
          }
        }
      }
    }
  `);

  return (
    <Popover className="">
      {({ open }) => (
        <>
          <Popover.Button className="p-2 inline-flex items-center justify-center">
            <span className="sr-only">Open menu</span>
            <Bars4Icon className="h-6 w-6 sm:h-7 sm:w-7 text-edgewater-dark hover:text-gray-900 dark:text-edgewater-400 dark:hover:text-edgewater-700" aria-hidden="true" />
          </Popover.Button>

          <Transition
            show={open}
            as={Fragment}
            enter="transition duration-200 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Popover.Panel
              static
              className="pb-4 absolute inset-x-0 sm:inset-x-auto sm:right-0 sm:min-w-[16rem] shadow-lg bg-edgewater-200 dark:bg-edgewater-800"
            >
              <ul className="mt-0 divide-y divide-edgewater-500">
                <Disclosure as="li">
                    <Disclosure.Button className="w-full">
                      <div className="w-full flex justify-between items-center gap-4">
                        <div className="px-4 py-3 text-lg dark:text-edgewater-400">
                          Shop
                        </div>
                        <PlusIcon className="m-2 h-5 w-5 text-gray-700 dark:text-edgewater-400" aria-hidden="true" />
                      </div>
                    </Disclosure.Button>
                  <Disclosure.Panel>
                    <div className="p-4 pt-2">
                      <nav className="">
                        <ul className="">
                          <li
                            className="pb-4 leading-normal"
                          >
                            <Popover.Button
                              as={Link}
                              to="/shop/"
                              className={`${ location.pathname === '/shop/' ? ' font-bold border-0' : '' }`}
                            >
                              All Products
                            </Popover.Button>
                          </li>
                          {data.collections.edges.map(({ node }, i) => {
                            return (
                              <li
                                key={`collections-${node.id}`}
                                className="pb-4 ml-3 leading-normal"
                              >
                                <Popover.Button
                                  as={Link}
                                  to={node.collectionPath}
                                  className={`${ node.collectionPath === location.pathname ? ' font-bold border-0' : '' }`}
                                >
                                  {node.title}
                                </Popover.Button>
                              </li>
                            )
                          })}
                        </ul>
                      </nav>
                    </div>
                  </Disclosure.Panel>
                </Disclosure>
                {data.pages.edges.map(({ node }) => {
                  return (
                    <li
                      key={node.uid}
                    >
                      <Popover.Button as={Link}
                        to={`/${node.uid}`}
                        className="block text-lg px-4 py-3 whitespace-nowrap border-0 dark:text-edgewater-400"
                      >
                        {node.data.title.text}
                      </Popover.Button>
                    </li>
                  )
                })}
                <li>
                  <Popover.Button as={Link}
                    to="/gallery/"
                    className="block text-lg px-4 py-3 whitespace-nowrap border-0 no-underline dark:text-edgewater-400"
                  >
                    Gallery
                  </Popover.Button>
                </li>
              </ul>
              <div className="flex justify-end">
                <Popover.Button className="px-4 py-2 items-center justify-center hover:text-gray-900 dark:text-edgewater-400">
                  <span className="link-style">Close</span>
                  {/*<XIcon className="h-6 w-6 m-1" aria-hidden="true" />*/}
                </Popover.Button>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
};

export default DropdownMenu;
