import React from "react";
import Form from './Form';

const Footer = () => (
  <footer className="footer bg-edgewater-500 dark:bg-edgewater-dark">
    <section className="container mx-auto px-6 py-12 md:py-16">
      <div className="grid sm:grid-cols-2 gap-6 md:gap-8">
        <div className="order-1 sm:order-2 sm:row-span-2">
          <Form />
        </div>
        <div className="order-2 sm:order-1 text-center sm:text-left">
          <a aria-label="nest.embirdery on Instagram" className="inline-block mx-1 border-b-0" href="https://www.instagram.com/nest.embirdery/" target="_blank" rel="nofollow noreferrer">
            <svg aria-hidden="true"  className="w-8 h-8 dark:stroke-edgewater" width="32" height="32" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1" fill="none" strokeLinecap="round" strokeLinejoin="round"><rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path><line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line></svg>
          </a>
          <a aria-label="nest.embirdery on Facebook" className="inline-block mx-1 border-b-0" href="https://www.facebook.com/nest.embirdery/" target="_blank" rel="nofollow noreferrer">
            <svg aria-hidden="true" className="w-8 h-8 dark:stroke-edgewater" width="32" height="32" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1" fill="none" strokeLinecap="round" strokeLinejoin="round"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></svg>
          </a>
        </div>
        <div className="order-3 sm:order-2 text-sm text-center sm:text-left dark:text-white">
          Copyright &copy; 2021-2023 Nest Embirdery. All Rights Reserved.
        </div>
      </div>
    </section>
  </footer>
);

export default Footer;
