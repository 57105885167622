exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cart-index-js": () => import("./../../../src/pages/cart/index.js" /* webpackChunkName: "component---src-pages-cart-index-js" */),
  "component---src-pages-events-prismic-event-uid-js": () => import("./../../../src/pages/events/{prismicEvent.uid}.js" /* webpackChunkName: "component---src-pages-events-prismic-event-uid-js" */),
  "component---src-pages-gallery-index-js": () => import("./../../../src/pages/gallery/index.js" /* webpackChunkName: "component---src-pages-gallery-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-prismic-page-uid-js": () => import("./../../../src/pages/{prismicPage.uid}.js" /* webpackChunkName: "component---src-pages-prismic-page-uid-js" */),
  "component---src-pages-shop-collection-shopify-collection-title-js": () => import("./../../../src/pages/shop/collection/{shopifyCollection.title}.js" /* webpackChunkName: "component---src-pages-shop-collection-shopify-collection-title-js" */),
  "component---src-pages-shop-index-js": () => import("./../../../src/pages/shop/index.js" /* webpackChunkName: "component---src-pages-shop-index-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-thank-you-order-js": () => import("./../../../src/pages/thank-you-order.js" /* webpackChunkName: "component---src-pages-thank-you-order-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

